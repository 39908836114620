import React from "react";
import styles from "./LoaderScreen.module.scss";
import wellnessLogo from "@assets/logos/wellness-logo.png";

const LoaderScreen = () => {
	return (
		<div className={styles.loaderScreenMain}>
			<img src={wellnessLogo} alt="wellness-studio" />
		</div>
	);
};

export default LoaderScreen;
