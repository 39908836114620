import React, { ChangeEvent, useState } from "react";
import "./MissingPolicies.scss";
import { useUserContext } from "@src/context/UserContext";
import { Policies } from "@src/Interfaces/Policies";
import { useFormik } from "formik";
import { array, number, object } from "yup";
import wellnessLogo from "@assets/logos/wellness-logo.png";
import { acceptMultiplePolicies } from "@src/services/UserService";

const MissingPolicies = () => {
	const { missingPolicies, signOut, getMissingPolicies } = useUserContext();

	const formik = useFormik<{ accepted: number[] }>({
		initialValues: { accepted: [] },
		validationSchema: object({
			accepted: array().of(number()).min(missingPolicies.length, "Es necesario aceptar nuestras políticas."),
		}),
		onSubmit: async (values) => {
			try {
				await acceptMultiplePolicies(values.accepted);
				getMissingPolicies();
			} catch (error) {
				console.error(error);
			}
		},
	});

	const handleChecked = (checked: boolean, policyId: number) => {
		const temp = [...formik.values.accepted];

		if (checked) {
			formik.setFieldValue("accepted", [...temp, policyId]);
			return;
		}

		formik.setFieldValue(
			"accepted",
			temp.filter((e) => e !== policyId)
		);
	};

	const { errors, touched } = formik;

	return (
		<div className="missing-policies-main-container">
			<img className="logo" src={wellnessLogo} alt="wellness logo" />
			<h1>Actualización de Políticas</h1>
			<p className="description">
				Hemos actualizado nuestros{" "}
				{missingPolicies.map((policy: Policies, index: number) => (
					<b className="policy" key={policy.id}>
						{index !== 0 &&
							`${missingPolicies.length > 1 && index !== missingPolicies.length - 1 ? ", " : " y "}`}
						{`${policy.spanish_title.toLowerCase()}`}
					</b>
				))}
				. Para continuar utilizando la plataforma, por favor revisa y acepta los siguientes documentos. Haz
				clic en cada enlace para leer los detalles.
			</p>

			<form onSubmit={formik.handleSubmit}>
				{missingPolicies.map((policy: Policies) => (
					<div className="policy-input-container" key={policy.id}>
						<input
							type="checkbox"
							checked={formik.values.accepted.includes(policy.id)}
							onChange={(e) => handleChecked(e.target.checked, policy.id)}
						/>
						<p>
							He leído y acepto{" "}
							<a href={policy.link} target="_blank" rel="noopener noreferrer">
								{policy.spanish_title}
							</a>
						</p>
					</div>
				))}

				{errors.accepted && touched.accepted && <p className="error">{errors.accepted}</p>}

				<div className="buttons-container">
					<button className="sign-out-btn btn secondary" type="button" onClick={signOut}>
						Cerrar sesión
					</button>
					<button type="submit" className="btn primary" disabled={formik.isSubmitting}>
						Aceptar
					</button>
				</div>
			</form>
		</div>
	);
};

export default MissingPolicies;
