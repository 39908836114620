import "./MainLayout.scss";
import Navbar from "@src/components/Navbar/Navbar";
import { Outlet } from "react-router-dom";
import { useUserContext } from "@src/context/UserContext";

interface MainLayoutPropsI {}

function MainLayout(props: MainLayoutPropsI) {
	const { user } = useUserContext();

	return (
		<div className="layout-main-container">
			{user && <Navbar />}
			<Outlet />
		</div>
	);
}

export default MainLayout;
